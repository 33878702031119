import { DocumentType, UserType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '../../../../containers/Admin/props';
import IconUserCircle from '../../../../icons/UserCircle';
import ListItem from '../index';

const ListItemUser: FC<ListItemProps<UserType>> = ({
  docId,
  index,
  model,
  onClick,
}) => {
  const [doc, setDoc] = useState<DocumentType<UserType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <ListItem displayActionId={false} doc={doc} index={index} onClick={onClick}>
      <div className="flex space-x-3 items-center">
        {doc.uid && <IconUserCircle />}
        <span>
          {doc.firstName} {doc.lastName}
        </span>
        {doc.stripeAccountId && (
          <span className="text-light-800">Compte connecté</span>
        )}
        {doc.stripeAccountVerified && (
          <span className="text-light-800">Vérifié</span>
        )}
      </div>
    </ListItem>
  );
};

export default ListItemUser;
